<template>
<div class="page_content_wrap">
    
	<div class="let_gets_to_know_you_wrap py-5">
		<div class="container">
			<div class="let_gets_to_know_you_box text-center">
                <h3 class="text-dark mb-3">Other ways to receive money from your annuity</h3>
                <p>All about the flexibility that annuities provide.</p>
            </div>
            <div class="video-ifream text-center">
                <iframe src="//fast.wistia.com/embed/iframe/g2kg988fv7?videoFoam=true&amp;playbar=false" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" oallowfullscreen="oallowfullscreen" msallowfullscreen="msallowfullscreen" width="1000"  height="620"></iframe>
            </div>
		</div>
    </div>

    <div class="knowledge_toggle-section pb-5">
        <div class="container">
            <div class="knowledge_inner-box px-5">
                <div class="knowledge_toggle_box text-dark ">
                    <p class=" font-size-sm">Let's test your knowledge</p>
                    <p>Both annuitization and the income rider can offer guaranteed income for life. Which offers the flexibility to have access to your cash value once payments start?</p>
                </div>
                <div class="accordion" id="accordion_one">
                    <div class="accordion_box bg-pastel-success mb-3">
                        <div class="accordion_header border px-4">
                            <h5 class="collapsed" data-toggle="collapse" data-target="#accordion_one_1" aria-expanded="false">
                                01. Annuitization
                            </h5>
                        </div>
                        <div id="accordion_one_1" class="collapse p-4" data-parent="#accordion_one" style="">
                            <div class="accordion_body">Incorrect - Annuitization payments are irrevocable once elected and do not allow access to your annuity’s cash value.</div>
                        </div>
                    </div>
                    <div class="accordion_box bg-pastel-success mb-3">
                        <div class="accordion_header border px-4">
                            <h5 class="collapsed" data-toggle="collapse" data-target="#accordion_one_2" aria-expanded="false">
                                02.Income Rider
                            </h5>
                        </div>
                        <div id="accordion_one_2" class="collapse p-4" data-parent="#accordion_one" style="">
                            <div class="accordion_body">Correct - The income rider ensures that you will receive guaranteed lifetime income payments, continue to earn interest, and pass on the remainder to your beneficiaries in the option that they choose either a lump sum or annuity payments</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="knowledge_toggle_box text-dark font-weight-bold">
                    <p><span class="text-success">Correct -</span> The Death Benefit equals at least the accumulation value, but state law may require it to be higher if the accumulation value doesn’t meet a certain minimum required by state law.</p>
                </div> -->
            </div>
        </div>
    </div>

    <!-- Tabs -->
    <section class="pb-5 information-section" id="tabs">
        <div class="container">
            <div class="information-inner px-5">
                <h6 class="section-title text-dark">Key Information</h6>
                <p class="text-dark">Below is some key information regarding the ways in which you can receive money from your annuity:</p>
                <div class="row">
                    <div class="col-12">
                        <nav>
                            <div class="nav nav-tabs nav-fill mb-0" id="nav-tab" role="tablist">
                                <a class="text-left active h6" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">MNL ENDEAVOR 12</a>
                            </div>
                        </nav>
                        <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <table class="table table-striped text-dark">
                                    <tbody>
                                    <tr>
                                        <td>PENALTY FREE WITHDRAWAL PERCENTAGE</td>
                                        <td>
                                        <p>10% of Accumulation Value once per year after the first contract anniversary</p>   
                                        <ul>
                                        <li>Once a payout option is elected, it cannot be changed and all other rights and benefits under the annuity end.</li>
                                        <li>In all states except Florida, you may select an annuity payout option at any time. If selected during the Surrender Charge period, your contract allows the payout to be based upon the surrender value rather than accumulation value.</li>
                                        <li>In Florida, you may select an annuity payout option based on the Accumulation Value at any time after the first contract year.</li>
                                        </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ANNUITY PAYOUT OPTIONS</td>
                                        <td>
                                        <p>Payout options for all states except Florida:</p>   
                                        <ul>
                                            <li>Life Income</li>
                                            <li>Life Income with Period Certain</li>
                                            <li>Joint and Survivor Income</li>
                                            <li>Income for a Specified Period</li>
                                            <li>Income for a Specified Amount</li>
                                        </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                        <p>Florida:</p>   
                                        <ul>
                                            <li>Life Income</li>
                                            <li>Life Income with 10 Year Period Certain</li>
                                            <li>Life Income with 20 Year Period Certain</li>
                                            <li>Joint and Survivor Income with 10 Year Period Certain</li>
                                            <li>Joint and Survivor Income with 20 Year Period Certain</li>
                                            <li>Joint and Survivor Income for Life</li>
                                        </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>NURSING HOME CONFINEMENT WAIVER AVAILABILITY</td>
                                        <td>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>ADDITIONAL NURSING HOME CONFINEMENT PENALTY FREE WITHDRAWAL PERCENTAGE</td>
                                        <td>10.0% available after first contract anniversary</td>
                                    </tr>
                                    <tr>
                                        <td>LIFETIME PAYMENT AMOUNT MULTIPLIER AVAILABILITY</td>
                                        <td>Yes</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 
    <!-- ./Tabs -->

    <div class="more_information_section pb-5">
        <div class="container">
            <div class="knowledge_inner-box px-5">
                <div class="accordion" id="accordion_one">
                    <div class="accordion_box mb-3">
                        <div class="accordion_header border px-4">
                            <h5 class="collapsed" data-toggle="collapse" data-target="#information" aria-expanded="false">More information</h5>
                        </div>
                        <div id="information" class="collapse p-4" data-parent="#information">
                            <div class="accordion_body text-dark">
                                <div class="accumulation pb-3">   
                                    <h6>What are Penalty-Free Withdrawals?</h6>
                                    <p class="font-size-sm">Penalty-free withdrawals (also known as Penalty-Free Partial Surrenders), are a percentage of your Accumulation Value that you are allowed to withdraw from your annuity once per year without Surrender Charges or Interest Adjustment. Penalty-free withdrawals are available after the first contract anniversary.</p>
                                </div>
                                <div class="accumulation pb-3">   
                                    <h6>What is the Nursing Home Confinement Waiver?</h6>
                                    <p class="font-size-sm">After the first Contract Anniversary, if you become confined to a qualified nursing home facility for at least 90 consecutive days, we will increase the penalty-free withdrawal amount by a percentage of the Accumulation Value each year while you are confined. See the information in the gray box for the percentage specific to your product and/or state. This waiver is automatically included with your annuity at no additional charge.</p> 
                                    <p class="font-size-sm">The Nursing Home Confinement Waiver is available based on annuitant’s confinement and is not available in all states or to individuals who are older than 75 at the time the annuity is purchased.</p>
                                </div>
                                <div class="accumulation pb-3">   
                                    <h6>What is the LPA Multiplier feature?</h6>
                                    <p class="font-size-sm">The LPA Multiplier allows you to double your lifetime payment amount if you become confined to a qualified nursing care facility for at least 90 consecutive days. The LPA Multiplier is available for up to five annual payments, which do not have to be consecutive, so long as all requirements are met. To qualify for this benefit, you cannot be confined to a nursing care facility at the time the annuity was issued, and you must wait at least two years after the annuity was issued before receiving the benefit. The feature is no longer available when your accumulation value reaches zero. Your lifetime payment amount will revert back to the original amount if you are no longer eligible.</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 

     <div class="disclaimer py-6">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="more-information text-dark">
                        <h5>Disclaimer</h5>
                        <p>The MNL Endeavor 12 is issued by Midland National® Life Insurance Company, West Des Moines, IA. This product, its features and riders may not be available in all states. Please request the product disclosure for additional details and limitations.</p>
                        <p class="mb-0">Fixed Index Annuities are not a direct investment in the stock market. They are long term insurance products with guarantees backed by the issuing company. They provide the potential for interest to be credited based in part on the performance of specific indices, without the risk of loss of premium due to market downturns or fluctuation. Although fixed index annuities guarantee no loss of premium due to market downturns, deductions from your accumulation value for additional optional benefit riders could under certain scenarios exceed interest credited to the accumulation value, which would result in a loss of premium. They may not be appropriate for all clients.</p>
                    </div>
                </div>
            </div>
        </div>
    </div> 

    <div class="footer_bottom py-4">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="back-box d-inline-block pt-3">
                        <a class="text-dark h5 text-decoration-none" href="#"><i class="far fa-arrow-left mr-2"></i>Back</a>
                    </div>
                    <div class="understand-box d-inline-block float-right">
                        <p class="d-inline-block mr-3 text-dark">Please watch the video to continue</p>
                        <a href="#" class="btn btn-primary btn-lg">I Understand, Continue<i class="far fa-arrow-right ml-2"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>